import orderBy from 'lodash.orderby';

import './InstantInfo.css';

import Item from './Item';

function PlaylistInfo({ data }) {
  const orderedList = orderBy(data.guestData.options, ['current', 'listened', 'paid', 'votes', 'sortingValue'], ['desc', 'asc', 'asc', 'desc', 'asc', 'asc']);
  
  const limitedOptions = orderedList.slice(1, 8);
  const size = (window.innerHeight * 0.95) / limitedOptions.length;
  return (
    <div className="instant-info">
      {limitedOptions.map((option) => (
        <Item key={option.uri} size={size} item={option} />
      ))}
    </div>
  );
}

export default PlaylistInfo;
