import './InstantInfo.css';

import Item from './Item';

function InstantInfo({ data }) {
  const size = (window.innerHeight * 0.9) / data.guestData.options.length;
  return (
    <div className="instant-info">
      {data.guestData.options.map((option) => (
        <Item key={option.uri} size={size} item={option} />
      ))}
    </div>
  );
}

export default InstantInfo;
