import GoogleLogin from 'react-google-login';
import AppleLogin from 'react-apple-login';
import toast from 'react-hot-toast';

import logo from './logo.png';
import './Login.css';

function Login({ onComplete }) {
  const handleGoogleResponse = async result => {
    if (result.error) {
      console.log(result);
      toast.error(result.details);
    } else {
      onComplete({ provider: 'google', token: result.accessToken });
    }
  };

  const handleAppleResponse = async result => {
    if (result.authorization) {
      onComplete({ provider: 'apple', token: result.authorization.id_token });
    } else {
      console.log(result);
    }
  };

  return (
    <div className="login">
      <div>
        <img src={logo} className='logo' />
      </div>
      <div className='button'>
        <GoogleLogin
          clientId={process.env.REACT_APP_WEB_GOOGLE_CLIENT_ID}
          buttonText="Acceder con Google"
          onSuccess={handleGoogleResponse}
          onFailure={handleGoogleResponse}
          usePopup={false}
        />
      </div>
      <div className='button'>
        <AppleLogin
          clientId={process.env.REACT_APP_APPLE_CLIENT_ID}
          redirectURI={process.env.REACT_APP_APPLE_REDIRECT_URI}
          callback={handleAppleResponse}
          usePopup={false}
        />
      </div>
    </div>
  );
}

export default Login;
