function Item({ item, size }) {
  return (
    <div className='instant-info-option'>
      <img src={item.image} height={size} width={size} />
      <div className='instant-info-option-details'>
        <div className='instant-info-option-name'>{item.name}</div>
        <div className='instant-info-option-artist'>{item.artist}</div>
      </div>
      <div className='instant-info-option-votes'>
        <div className='instant-info-option-votes-number'>{item.paid ? '$' : item.votes}</div>
        <div className='instant-info-option-votes-text'>{item.paid ? '' : 'votos'}</div>
      </div>
    </div>
  );
}

export default Item;
