import { useEffect, useState } from 'react';
import Login from './Login';
import Info from './Info';

import './App.css';

function App() {
  const [token, setToken] = useState(null);
  const [info, setInfo] = useState(JSON.parse(localStorage.getItem('user') || 'null'));

  useEffect(() => {
    if (token) {
      const url = `${process.env.REACT_APP_SERVER_BASE_URL}/login/token`;
      fetch(url, {
        method: 'POST',
        body: JSON.stringify({
          provider: token.provider,
          accessToken: token.token,
        }),
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      }).then(res => res.json()).then((res) => {
        setInfo(res);
        localStorage.setItem('user', JSON.stringify(res));
      }).catch((e) => {
        console.log('Error getting token', e);
        setToken(null);
      });
    }
  }, [token]);

  return (
    <div className="App">
      {!info && !token && (
        <Login onComplete={(t) => setToken(t)} />
      )}
      {info && (
        <Info info={info} onSessionExpire={() => {
          setInfo(null);
          localStorage.setItem('user', null);
        }} />
      )}
    </div>
  );
}

export default App;
