import { useEffect, useState } from 'react';
import InstantInfo from './InstantInfo';
import PlaylistInfo from './PlaylistInfo';

import logo from './logo.png';
import './Info.css';

const TIME = 15000;

function Info({ info, onSessionExpire }) {
  const [data, setData] = useState();
  const [active, setActive] = useState(1);

  useEffect(() => {
    let active = true;
    const requestData = () => {
      const url = `${process.env.REACT_APP_SERVER_BASE_URL}/tv`;
      fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          'userauthorization': info.userAuthorization + '',
        },
      }).then(res => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 401) {
          onSessionExpire();
          throw new Error('Network error');
        } else {
          throw new Error('Network error');
        }
      }).then((res) => {
        setData(res);
        if (active) {
          setTimeout(requestData, 5000);
        }
      }).catch((e) => {
        if (active) {
          setTimeout(requestData, 5000);
        }
      });
    };

    requestData();

    return () => {
      active = false;
    }
  }, [info]);

  useEffect(() => {
    setTimeout(() => {
      setActive(active === 1 ? 0 : 1);
    }, TIME)
  }, [active]);

  if (!data || !data.guestData) {
    return null;
  }

  return (
    <div className="info">
      <div className="info-left">
        {data.guestData.currentTrack && (
          <>
            <div className='info-header'>
              <img src={logo} className='logo' />
              {data.settings.displayPicture && (
                <img src={`${process.env.REACT_APP_AWS_BUCKET_URL}/images/${data.settings.displayPicture}`} className='host-image' />
              )}
            </div>
            <div className='info-track' style={{ display: active === 1 ? 'flex' : 'none'}}>
              <img src={data.guestData.currentTrack.image} />
              <div>{data.guestData.currentTrack.name}</div>
              <div>{data.guestData.currentTrack.artist}</div>
            </div>
            <div className='info-track' style={{ display: active === 0 ? 'flex' : 'none'}}>
              <img src={`${process.env.REACT_APP_AWS_BUCKET_URL}/qr/${data.id}.png`} />
              <div>Elegí la siguiente canción!</div>
              <div>www.loudy.app</div>
            </div>
          </>
        )}
      </div>
      <div className="info-right">
        {data.guestData.mode === 'instant mode' ? <InstantInfo data={data} /> : <PlaylistInfo data={data} />}
      </div>
    </div>
  );
}

export default Info;
